/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'waypoints/lib/noframework.waypoints';
import 'lazysizes';

// example: Wraps every page in a component
// export const wrapPageElement = ( {element, props} ) => {
//     return <Layout {...props}>{element}</Layout>
// }

if ( 'serviceWorker' in navigator ) {
    navigator.serviceWorker.getRegistrations()
        .then( function ( registrations ) {
            for ( let registration of registrations ) {
                registration.unregister();
            }
        } );
}
