exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-component-library-js": () => import("./../../../src/pages/component-library.js" /* webpackChunkName: "component---src-pages-component-library-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-mixing-filling-js": () => import("./../../../src/pages/services/mixing-filling.js" /* webpackChunkName: "component---src-pages-services-mixing-filling-js" */),
  "component---src-pages-services-packaging-js": () => import("./../../../src/pages/services/packaging.js" /* webpackChunkName: "component---src-pages-services-packaging-js" */),
  "component---src-pages-services-private-label-js": () => import("./../../../src/pages/services/private-label.js" /* webpackChunkName: "component---src-pages-services-private-label-js" */),
  "component---src-pages-services-rd-development-js": () => import("./../../../src/pages/services/rd-development.js" /* webpackChunkName: "component---src-pages-services-rd-development-js" */),
  "component---src-pages-smarter-to-market-js": () => import("./../../../src/pages/smarter-to-market.js" /* webpackChunkName: "component---src-pages-smarter-to-market-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-one-js": () => import("./../../../src/pages/thank-you-one.js" /* webpackChunkName: "component---src-pages-thank-you-one-js" */),
  "component---src-templates-product-layout-js": () => import("./../../../src/templates/product-layout.js" /* webpackChunkName: "component---src-templates-product-layout-js" */)
}

